<template>
  <v-container fluid>
    <v-tabs>
      <v-tab> {{ $t("settings.userSettings") }} </v-tab>
      <v-tab :disabled="!permitted('GlobalSettings.View')">
        {{ $t("accessToken.title") }}
      </v-tab>
      <v-tab :disabled="!permitted('GlobalSettings.View')">
        {{ $t("settings.global.name") }}
      </v-tab>
      <v-tab :disabled="!permitted('CompanySettings.View')">
        {{ $t("settings.company.title") }}
      </v-tab>
      <v-tab :disabled="!permitted('Mqtt.View')">{{
        $t("mqtt.settingsTabTitle")
      }}</v-tab>
      <v-tab-item>
        <v-form
          :lazy-validation="true"
          @submit.prevent="handleSubmit"
          ref="form"
          class="px-5"
        >
          <!-- Create field for theme-->
          <v-select
            v-model="setting.theme"
            :items="themes"
            :placeholder="$t('settings.themes')"
            :label="$t('settings.themes')"
            :hide-details="false"
            class="pt-5"
          ></v-select>

          <v-switch
            :label="$t('settings.data.toolbarTransparancy')"
            v-model="setting.toolbarTransparent"
          ></v-switch>
          <v-switch
            :label="$t('settings.data.stickySideMenu')"
            v-model="setting.stickySideMenu"
          ></v-switch>
          <v-btn x-large color="primary" type="submit">{{
            $t("common.save")
          }}</v-btn>
        </v-form>
      </v-tab-item>
      <v-tab-item>
        <access-tokens v-if="permitted('GlobalSettings.View')" />
      </v-tab-item>
      <v-tab-item>
        <global-settings />
      </v-tab-item>

      <v-tab-item>
        <company-settings />
      </v-tab-item>

      <v-tab-item>
        <broker-list />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import { Roles } from "@/_helpers/Role";
import { mapActions, mapState } from "vuex";
import AccessTokens from "../accessToken/AccessTokens.vue";
import GlobalSettings from "./GlobalSettings.vue";
import CompanySettings from "./CompanySettings.vue";
import BrokerList from "../mqtt/BrokerList.vue";

export default {
  name: "SettingEdit",
  data() {
    return {
      theme: "Scientific",
      themes: ["Scientific", "Colors"],
      setting: {
        theme: "Scientific",
        toolbarTransparent: true,
        stickySideMenu: true,
      },
    };
  },

  async created() {
    await this.getUserSettings();

    if (Object.keys(this.userSettings).length < 0) {
      await this.setUserSettings(this.setting);
    }

    this.setting = this.userSettings;
  },

  methods: {
    ...mapActions("configuration", ["getUserSettings", "setUserSettings"]),

    async handleSubmit() {
      await this.setUserSettings(this.setting);
      this.setting = this.userSettings;
    },
  },

  computed: {
    ...mapState("configuration", ["userSettings"]),
    ...mapState("users", ["currentUser"]),

    userIsAdmin() {
      return this.currentUser.role == "SuperAdmin";
    },

    Roles() {
      return Roles;
    },
  },

  components: { AccessTokens, GlobalSettings, CompanySettings, BrokerList },
};
</script>